<template lang="pug">
v-app-bar(color="#026B81" height="72").elevation-0.px-10
  div(@click="$route.path !== '/' && $router.push('/')").d-flex.cursor-pointer
    img(src="@/assets/images/logo.svg" width="40" height="40" alt="logo")
    div.width-62.fs-9.font-weight-300.ml-2.font-head-light.white--text {{ $t('logoTitle') }}
  v-spacer
  div
    v-menu(offset-y)
      template(#activator="{ on, attrs }")
        div(v-bind="attrs" v-on="on").d-flex
          div.mr-2.fw-400.white--text {{ lang.toUpperCase() }}
          v-icon(color="white") mdi-chevron-down
      v-list
        v-list-item(v-for="(item, index) in additionalLanguages" :key="index" link @click="changeLanguage(item)")
          v-list-item-title {{ item.toUpperCase() }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Header',
  data () {
    return {
      additionalLanguages: ['ua', 'en']
    }
  },
  computed: {
    ...mapState({
      lang: state => state.app.lang
    })
  },
  methods: {
    changeLanguage (lang) {
      if (this.$route.params.lang !== lang) {
        this.$router.push(`/${ lang }/`)
        this.$store.commit('SET_DATA_APP', { lang })
      }
    }
  }
}
</script>